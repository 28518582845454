import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/atlassian/pipelines/agent/build/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from "docz";
import { PackageMetadata } from "@happeo/docs-tools";
import TranslationProvider from "../../../src/gatsby-theme-docz/TranslationProvider";
import pkg from "../package.json";
import { ImageCarousel } from "../src/index";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "image-carousel"
    }}>{`Image-carousel`}</h1>
    <PackageMetadata pkg={pkg} mdxType="PackageMetadata" />
    <p>{`An image carousel is a container (slideshow) of images or info that users can select by clicking a button that directs them forward or backward in the slideshow`}</p>
    <h2 {...{
      "id": "image-array-specs"
    }}>{`Image array specs`}</h2>
    <p>{`The component takes in an array of images. An example array is below:`}</p>
    <pre><code parentName="pre" {...{}}>{`const images = [
    {
      src: "https://source.unsplash.com/2ShvY8Lf6l0/800x599",
      alt: "This is alt text"
    },
    {
      src: "https://source.unsplash.com/u9cG4cuJ6bU/4927x1000",
      alt: "A wide image"
    }
  ];
`}</code></pre>
    <Playground __position={1} __code={'<TranslationProvider>\n  <ImageCarousel\n    images={[\n      {\n        src: \'https://source.unsplash.com/2ShvY8Lf6l0/800x599\',\n        alt: \'This is alt yo\',\n      },\n      {\n        src: \'https://source.unsplash.com/collection/190727/1600x900\',\n        alt: \'Image about stuff\',\n      },\n      {\n        src: \'https://source.unsplash.com/qDkso9nvCg0/600x799\',\n        alt: \'More alt stuff\',\n      },\n      {\n        src: \'https://source.unsplash.com/random/400x400\',\n        alt: \'There should always be alt\',\n      },\n      {\n        src: \'https://source.unsplash.com/u9cG4cuJ6bU/4927x1000\',\n        alt: \'A wide image\',\n      },\n    ]}\n  />\n</TranslationProvider>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PackageMetadata,
      TranslationProvider,
      pkg,
      ImageCarousel,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <TranslationProvider mdxType="TranslationProvider">
    <ImageCarousel images={[{
          src: "https://source.unsplash.com/2ShvY8Lf6l0/800x599",
          alt: "This is alt yo"
        }, {
          src: "https://source.unsplash.com/collection/190727/1600x900",
          alt: "Image about stuff"
        }, {
          src: "https://source.unsplash.com/qDkso9nvCg0/600x799",
          alt: "More alt stuff"
        }, {
          src: "https://source.unsplash.com/random/400x400",
          alt: "There should always be alt"
        }, {
          src: "https://source.unsplash.com/u9cG4cuJ6bU/4927x1000",
          alt: "A wide image"
        }]} mdxType="ImageCarousel" />
  </TranslationProvider>
    </Playground>
    <h3 {...{
      "id": "image-carousel-with-image-height"
    }}>{`Image Carousel with Image Height`}</h3>
    <pre><code parentName="pre" {...{}}>{`  <ImageCarousel
    images={images}
    imageHeight="208px"
  />
`}</code></pre>
    <Playground __position={2} __code={'<TranslationProvider>\n  <ImageCarousel\n    imageHeight=\"208px\"\n    images={[\n      {\n        src: \'https://source.unsplash.com/2ShvY8Lf6l0/800x599\',\n        alt: \'This is alt yo\',\n      },\n      {\n        src: \'https://source.unsplash.com/collection/190727/1600x900\',\n        alt: \'Image about stuff\',\n      },\n      {\n        src: \'https://source.unsplash.com/qDkso9nvCg0/600x799\',\n        alt: \'More alt stuff\',\n      },\n      {\n        src: \'https://source.unsplash.com/random/400x400\',\n        alt: \'There should always be alt\',\n      },\n      {\n        src: \'https://source.unsplash.com/u9cG4cuJ6bU/4927x1000\',\n        alt: \'A wide image\',\n      },\n    ]}\n  />\n</TranslationProvider>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PackageMetadata,
      TranslationProvider,
      pkg,
      ImageCarousel,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <TranslationProvider mdxType="TranslationProvider">
    <ImageCarousel imageHeight="208px" images={[{
          src: "https://source.unsplash.com/2ShvY8Lf6l0/800x599",
          alt: "This is alt yo"
        }, {
          src: "https://source.unsplash.com/collection/190727/1600x900",
          alt: "Image about stuff"
        }, {
          src: "https://source.unsplash.com/qDkso9nvCg0/600x799",
          alt: "More alt stuff"
        }, {
          src: "https://source.unsplash.com/random/400x400",
          alt: "There should always be alt"
        }, {
          src: "https://source.unsplash.com/u9cG4cuJ6bU/4927x1000",
          alt: "A wide image"
        }]} mdxType="ImageCarousel" />
  </TranslationProvider>
    </Playground>
    <h2 {...{
      "id": "broken-images"
    }}>{`Broken Images`}</h2>
    <p>{`If an image encounters an error on loading, an error state component will be rendered in place.`}</p>
    <Playground __position={3} __code={'<TranslationProvider>\n  <ImageCarousel\n    images={[\n      {\n        src: \'ERROR-THIS-IS-NOT-A-SRC!\',\n      },\n      {\n        src: \'https://picsum.photos/1200/780\',\n      },\n      {\n        src: \'ERROR-THIS-IS-NOT-A-SRC!\',\n      },\n    ]}\n  />\n</TranslationProvider>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PackageMetadata,
      TranslationProvider,
      pkg,
      ImageCarousel,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <TranslationProvider mdxType="TranslationProvider">
    <ImageCarousel images={[{
          src: "ERROR-THIS-IS-NOT-A-SRC!"
        }, {
          src: "https://picsum.photos/1200/780"
        }, {
          src: "ERROR-THIS-IS-NOT-A-SRC!"
        }]} mdxType="ImageCarousel" />
  </TranslationProvider>
    </Playground>
    <h2 {...{
      "id": "one-image"
    }}>{`One image`}</h2>
    <p>{`If there's just one image, the arrows will be hidden`}</p>
    <Playground __position={4} __code={'<TranslationProvider>\n  <ImageCarousel\n    images={[\n      {\n        src: \'https://picsum.photos/1200/780\',\n      },\n    ]}\n  />\n</TranslationProvider>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PackageMetadata,
      TranslationProvider,
      pkg,
      ImageCarousel,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <TranslationProvider mdxType="TranslationProvider">
    <ImageCarousel images={[{
          src: "https://picsum.photos/1200/780"
        }]} mdxType="ImageCarousel" />
  </TranslationProvider>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      